import {
    ACTION_FETCH_CHILDREN,
    ACTION_FETCH_ME,
    ACTION_SET_PREPARATION_PERIOD_INFO,
    ACTION_SET_USER_LOAD
} from '@commonSchool/store/schoolActions.js';
import schoolApi from '@commonSchool/apis/schoolApi.js';
import {covertInstituteNoFromOrganizationId} from '@commonSchool/utils/convertOrganizationIdUtil.js';
import {CHILD_ID_ALL} from '@commonSchool/consts/childConsts.js';
import counselApi from "@common/apis/counselApi";
import {convertDashedStringToDate} from "@common/utils/dateUtil";

export const SCHOOL_USER_STORE_NAMESPACE = 'SCHOOL_USER_STORE_NAMESPACE';

const SET_LOAD = 'SET_LOAD';
const SET_ME = 'SET_ME';
const SET_CHILDREN = 'SET_CHILDREN';
const SET_PREPARATION_PERIOD_INFO = 'SET_PREPARATION_PERIOD_INFO';

export default {
    state: {
        load: false,
        me: null,
        children: null,
        preparationPeriodInfo: [],
    },

    getters: {
        selectedChild: state => ({childId}) => state.children?.find(({id}) => id === childId),
        me: state => state.me,
    },

    mutations: {
        [SET_LOAD](state, {load}) {
            state.load = load;
        },

        [SET_ME](state, {me}) {
            state.me = me;
        },

        [SET_CHILDREN](state, {children}) {
            const redesignedChildren = children.map(child => {
                const {
                    id,
                    name,
                    grade_desc: gradeDesc,
                    class_no: className,
                    organization,
                } = child;

                const {id: organizationId, name: schoolName} = organization || {};

                return {
                    id: String(id),
                    name,
                    gradeDesc,
                    className,
                    instituteNo: covertInstituteNoFromOrganizationId(organizationId),
                    schoolName,
                };
            });

            //자녀가 1명인 경우 '자녀 전체'로 selectBox 가 보이지는 않지만 내부적으로는 자녀전체로 돌아간다.
            if (redesignedChildren.length === 1) {
                state.children = [{
                    ...redesignedChildren[0],
                    id: CHILD_ID_ALL,
                }];
                return;
            }

            state.children = [
                {
                    id: CHILD_ID_ALL,
                    name: '자녀 전체',
                },
                ...redesignedChildren,
            ];
        },

        [SET_PREPARATION_PERIOD_INFO](state, {preparationPeriodInfo}) {
            state.preparationPeriodInfo = preparationPeriodInfo.list.map(info => {
                return {
                    ...info,
                    startDate: convertDashedStringToDate(info.startDate),
                    endDate: convertDashedStringToDate(info.endDate),
                }
            });
        },
    },

    actions: {
        async [ACTION_FETCH_ME]({commit}) {
            const {user: me} = await schoolApi.getMe();
            commit(SET_ME, {me});
        },

        [ACTION_SET_USER_LOAD]({commit}, {load}) {
            commit(SET_LOAD, {load});
        },

        async [ACTION_FETCH_CHILDREN]({commit}) {
            const children = await schoolApi.getChildren();
            commit(SET_CHILDREN, {children});
        },

        async [ACTION_SET_PREPARATION_PERIOD_INFO]({commit}) {
            const preparationPeriodInfo = await counselApi.getPreparationPeriodInfo();
            commit(SET_PREPARATION_PERIOD_INFO, {preparationPeriodInfo});
        },
    },
};
